import React from "react";
import Apis from "../../services/Apis";
const DetailAction =()=>{
    const {getdetaillist} =Apis();

    const getdetaillistdata =(input_data)=>async dispatch =>{
      dispatch({
        type: 'setloader',
        payload: true
      });
        const response = await getdetaillist(input_data);
        
        
        {
         
            if (response.data.status == 'success') {
                // alert()
                dispatch({
                    type: 'satDeataidata',
                    payload:response.data.productList,
                
                  });
                  dispatch({
                    type: 'setpopulardata',
                    payload:response.data.popularNews,
                
                  });
                } else {
                    // alert()
                  dispatch({

                    type: 'satDeataidata',
                    payload: []
                  });
                  dispatch({
                    type: 'setpopulardata',
                    payload:[],
                
                  });
                }
                dispatch({
                  type: 'setloader',
                  payload: false
                });
            }; 
        }
      
    
    return{
        getdetaillistdata:getdetaillistdata
    }
}
export default DetailAction;